/* eslint-disable max-len */
export default {
  frames: {
    ONE: 'https://ik.imagekit.io/t9mszy7n6/owassax/capfreeorig3pppp.png',
    TWO: 'https://ik.imagekit.io/t9mszy7n6/owassax/capfreeorig2pppp.png',
    THREE: 'https://ik.imagekit.io/t9mszy7n6/owassax/capfreeorig13pppp.png',
    FOUR: 'https://ik.imagekit.io/t9mszy7n6/owassax/capfreeorig4pppp.png',
    FIVE: 'https://ik.imagekit.io/t9mszy7n6/owassax/capfreeorig1pppp.png',
    SIX: 'https://ik.imagekit.io/t9mszy7n6/owassax/capfreeorig6pppp.png',
    SEVEN: 'https://ik.imagekit.io/t9mszy7n6/owassax/capfreeorig5pppp.png',
    EIGHT: 'https://ik.imagekit.io/t9mszy7n6/owassax/capfreeorig8pppp.png',
    NINE: 'https://ik.imagekit.io/t9mszy7n6/owassax/capfreeorig9pppp.png',
    TEN: 'https://ik.imagekit.io/t9mszy7n6/owassax/capfreeorig10pppp.png',
    ELEVEN: 'https://ik.imagekit.io/t9mszy7n6/owassax/capfreeorig14pppp.png',
    TWELVE: 'https://ik.imagekit.io/t9mszy7n6/owassax/capfreeorig7pppp.png',   
    THIRTEEN: 'https://ik.imagekit.io/t9mszy7n6/owassax/emptyorig.png',  
  },
};
